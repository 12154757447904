import { Link, useParams, useSearchParams } from "react-router-dom"
import { SampleNextArrow } from "../component/Arrows/SampleNextArrow";
import { SamplePrevArrow } from "../component/Arrows/SamplePrevArrow";
import Slider from "react-slick";
import bannerbg from '../image/banner-bg.png'
import { ProductInList } from "../component/ProductInList";
import { useEffect, useState } from "react";
import { FooterForm } from "../component/FooterForm";
import { Pagination } from "../component/Pagination";
import { CatalogFilter } from "../component/CatalogFilter";
import { Catalog, Compilations, host } from "../classes";
import { Loader } from "../component/Loader";
import { CatalogCategory } from "../component/CatalogCategory";
import { InfoBlock } from "../component/InfoBlock";

export const CatalogPage = () => {
    const { type } = useParams()
    const [catalog, setCatalog] = useState(new Catalog())
    const [compilations, setCompilations] = useState(new Compilations())
    const [load, setLoad] = useState(true) 
    
    const [price, setPrice] = useState({
        min: 0,
        max: 0
    })
    const [onlyAvailable, setOnlyAvailable] = useState(true)
    const [onlySale, setOnlySale] = useState(false)
    const [sort, setSort] = useState("-pk")

    const [sizes, setSizes] = useState<string[]>([])
    const [colors, setColors] = useState<number[]>([])
    const [material, setMaterial] = useState<number[]>([])
    const [brands, setBrands] = useState<number[]>([])
    const [category, setCategory] = useState<number[]>([])

    const [searchParams, setSearchParams] = useSearchParams();
    const [gender, setGender] = useState<string[]>(searchParams.get("gender") ? [`${searchParams.get("gender")}`] : [])
    const [searchText, setSearchText] = useState(searchParams.get("s") ? searchParams.get("s") : "")
    
    const LoadProducts = (page: number = 1, sorting: string = sort) => {
        setLoad(true)
        let categoryList = category
        console.log(categoryList)

        catalog.LoadProduct(
            page, 
            price.min, 
            price.max, 
            onlyAvailable, 
            onlySale, 
            sorting, 
            sizes.toString(), 
            colors.toString(), 
            material.toString(), 
            brands.toString(), 
            searchText ? searchText:"", 
            categoryList.toString(),
            "",
            gender.toString()
        ).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    }
    useEffect(()=>{
        compilations.LoadData().then(function(response){

        }).catch(function(error){
            alert("error")
        })

        if(searchParams.get("brand")){
            setBrands([Number(searchParams.get("brand"))])
        }

        if(type){
            switch(type){
                case "shoes":
                    setCategory([1])
                    break;
                case "clothes":
                    setCategory([2])
                    break;
                case "accessories":
                    setCategory([3])
                    break;
            }
        }
        console.log(category)

        catalog.LoadCategory(type).then(function(response){
            LoadProducts()
        }).catch(function(error){
            alert("error")
        })      
    },[])

    const setting = {
        slidesToShow: 6,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    arrows: false,
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 980,
                settings: {
                    arrows: false,
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 800,
                settings: {
                    arrows: false,
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 650,
                settings: {
                    arrows: false,
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    arrows: false,
                    slidesToShow: 1
                }
            }
        ]
    };

    if(load){
        return(
            <Loader />
        )
    }

    return(
        <>
            <section className="catalog">
                <div className="content">
                    <div className="brads">
                        <Link to="/">Главная</Link> / <span>Каталог</span>
                    </div>
                    <h1 className="title">
                        {
                            type == "" ? "Каталог": 
                            type == "shoes" ? "Обувь":
                            type == "clothes" ? "Одежда":
                            type == "accessories" ? "Аксессуары":"Каталог"
                        }
                    </h1>
                    <Slider {...setting}>
                        {
                            compilations.list.length ? (
                                compilations.list.map((item: any, index: number) => {
                                    return(
                                        <Link to={`/compilations/${item.slug}/`} className="catalog-banner">
                                            <div className="catalog-image" style={{backgroundImage: `url('${host}${item.image}')`}}></div>
                                            <h3>{item.title}</h3>
                                            <p>{item.miniDesc}</p>
                                        </Link>
                                    )
                                })
                            ):""
                        }
                    </Slider>

                    <div className="catalog-wrapper">
                        <div className="catalog-filter">
                            <div className="catalog-filter__wrapper">
                                <div className="price-wrapper catalog-filter__cat">
                                    <h3>Цена</h3>
                                    <div>
                                        <div className="row price">
                                            <div className="col-6">
                                                <input type="number" min={0} step={1} placeholder="от" value={price.min != 0 ? price.min: ""} name="min" onChange={(e)=>{
                                                    setPrice({
                                                        min: Number(e.target.value),
                                                        max: price.max
                                                    })
                                                }} />
                                            </div>
                                            <div className="col-6">
                                                <input type="number" min={0} step={1} placeholder="до" value={price.max != 0 ? price.max: ""} name="max" onChange={(e)=>{
                                                    setPrice({
                                                        min: price.min,
                                                        max: Number(e.target.value)
                                                    })
                                                }} />
                                            </div>
                                        </div>
                                        <div 
                                            className={`switcher ${onlyAvailable ? "active":""}`}
                                            onClick={()=>{
                                                setOnlyAvailable(!onlyAvailable)
                                            }}
                                        >
                                            Только в наличии
                                        </div>
                                        <div 
                                            className={`switcher ${onlySale ? "active":""}`}
                                            onClick={()=>{
                                                setOnlySale(!onlySale)
                                            }}
                                        >
                                            Только со скидкой
                                        </div>
                                        
                                    </div>
                                </div>

                                {
                                    catalog.category ? (
                                        <CatalogCategory title="Товарные категории" list={catalog.category} selected={category} setSelected={setCategory} /> 
                                    ):""
                                }
                                

                                <CatalogFilter title="Пол" list={catalog.gender} selected={gender} setSelected={setGender} />

                                
                                {
                                    catalog.sizes ? (
                                        <CatalogFilter title="Размеры" list={catalog.sizes} selected={sizes} setSelected={setSizes} />
                                    ):""
                                }
                                {
                                    catalog.color ? (
                                        <CatalogFilter title="Цвет" list={catalog.color} selected={colors} setSelected={setColors} />
                                    ):""
                                } 
                                {
                                    catalog.material ? (
                                        <CatalogFilter title="Материал" list={catalog.material} selected={material} setSelected={setMaterial} />
                                    ):""
                                } 
                                {
                                    catalog.brands ? (
                                        <CatalogFilter title="Бренды" list={catalog.brands} selected={brands} setSelected={setBrands} />
                                    ):""
                                } 
                                <button 
                                    className="catalog-filter__btn"
                                    onClick={()=>{
                                        LoadProducts()
                                    }}
                                >
                                    Найти
                                </button>
                                <a
                                    href={`/catalog/${type ? `${type}/`: ""}`}
                                    className="catalog-filter__clear"
                                >
                                    Очистить
                                </a>
                            </div>
                        </div>
                        <div className="catalog-space">
                            <form className="catalog-sort">
                                <label>
                                    Сортировка
                                    <select onChange={(e)=>{
                                        setSort(e.target.value)
                                        LoadProducts(1, e.target.value)
                                    }}>
                                        <option value={'-pk'} selected={sort == "-pk"}>По новизне</option>
                                        <option value={'-minSalePrice'} selected={sort == "-minSalePrice"}>По убыванию цены</option>
                                        <option value={'+priminSalePricece'} selected={sort == "+priminSalePricece"}>По возрастанию цены</option>
                                    </select>
                                </label>
                            </form>
                            <div className="row">
                                {
                                    catalog.products.length ? (
                                        catalog.products.map((item, index) => {
                                            return(
                                                <div className="col-md-6 col-lg-4 col-big-3">
                                                    <ProductInList key={index} data={item} />
                                                </div>
                                            )
                                        })
                                    ):""
                                }
                            </div>
                        
                            {
                                catalog.pageCount > 1 ? (
                                    <Pagination
                                        page={catalog.page}
                                        pageCount={catalog.pageCount}
                                        LoadProducts={LoadProducts}
                                    />
                                ):""
                            }
                        </div>
                    </div> 
                </div>  
            </section>

            <InfoBlock />
 
            <FooterForm />
        </>
    )
}