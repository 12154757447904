import axios from "axios"
import { error } from "console"
export const host = "https://api.teststeps.ru/"

export interface FAQItem{
    pk: number
    title: string
    content: string
}
export class FAQ{
    list: FAQItem[] = []
    constructor(){}

    async LoadData(){
        await axios.get(`${host}faq/`,{
            headers: {
                'Content-Type': 'application/json',

            }
        }).then(res => { 
            this.list = []
            console.log(res)
            res.data.forEach((item: FAQItem, index: number) => {
                this.list.push(item)
            })
        }).catch(function (error) { 
            console.log(error)
        }) 
        return true
    }
}

//-----------------------------------------------
export class BrandItem{
    pk: number
    slug: string
    title: string
    miniDesc: string
    logo: string
    banner: string = ""
    content: string = ""
    products: Product[] = []

    constructor(item: {
        pk: number
        slug: string
        title: string
        miniDesc: string
        logo: string
    } = {
        pk: 0,
        slug: "",
        title: "", 
        miniDesc: "",
        logo: ""
    }){
        this.pk = item.pk
        this.slug = item.slug
        this.title = item.title
        this.miniDesc = item.miniDesc
        this.logo = item.logo
    }

    async LoadData(){
        await axios.get(`${host}brand/${this.slug}/`,{
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            this.title = res.data.title
            this.banner = res.data.banner
            this.content = res.data.content
            
            this.products = []
            res.data.products.forEach((item: Product, index: number) => {
                this.products.push(new Product({...item}))
            })
        }).catch(error => {

        })
    }
}
export class Brands{
    pageCount: number = 1
    page: number = 1
    list: BrandItem[] = []

    async LoadData(page: number, sort: string){
        this.page = page
        await axios.get(`${host}brand/`,{
            params: {
                sort: sort,
                page: page,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {  
            this.list = []
            res.data.data.forEach((item: BrandItem) => {
                this.list.push(new BrandItem({...item}))
            })
        }).catch(error => {

        })
    }
}

//-----------------------------------------------
export class BlogItem{
    pk: number
    title: string
    slug: string
    image: string
    miniDesc: string

    banner: string = ""
    content: string = ""
    
    products: Product[] = []

    constructor(item: {
        pk: number
        title: string
        slug: string
        image: string
        miniDesc: string
    } = {
        pk: 0,
        title: "",
        slug: "",
        image: "",
        miniDesc: ""
    }){
        this.pk = item.pk
        this.title = item.title
        this.slug = item.slug
        this.image = item.image
        this.miniDesc = item.miniDesc
    }

    async LoadData(){
        await axios.get(`${host}blog/${this.slug}/`,{
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            this.title = res.data.title
            this.banner = res.data.banner
            this.content = res.data.content
            
            this.products = []
            res.data.products.forEach((item: Product, index: number) => {
                this.products.push(new Product({...item}))
            })

        }).catch(error => {

        })
    }
}
export class Blog{
    pageCount: number = 1
    page: number = 1
    list: BlogItem[] = []

    async LoadData(page: number){
        this.page = page
        await axios.get(`${host}blog/`,{
            params: {
                page: page,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            this.list = []
            res.data.data.forEach((item: BlogItem) => {
                this.list.push(new BlogItem({...item}))
            })
        }).catch(error => {

        })
    }
}

//-----------------------------------------------
export class CompilationItem{
    pageCount: number = 1
    page: number = 1
    products: Product[] = []

    title: string
    content: string = ""
    miniDesc: string
    slug: string
    image: string
    seoTitle: string = ""
    seoDesc: string = ""

    constructor(item: {title: string, slug: string, image: string, miniDesc: string} = {title: "", slug: "", image: "", miniDesc: ""}){
        this.title = item.title
        this.slug = item.slug
        this.image = item.image
        this.miniDesc = item.miniDesc
    }

    async LoadData(){
        await axios.get(`${host}compilations/${this.slug}/`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            this.title = res.data.title
            this.content = res.data.content
        }).catch(function(error){
            console.log(error)
        })

    }
}
export class Compilations{
    list: CompilationItem[] = []

    async LoadData(){
        await axios.get(`${host}compilations/`, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            this.list = []
            res.data.forEach((item: CompilationItem) => {
                this.list.push(new CompilationItem({...item}))
            })
        }).catch(function(error){
            console.log(error)
        })
    }
}

//-----------------------------------------------
export interface CatalogCategory{
    pk: number
    title: string
    slug: string
    sort: number
    children: CatalogCategory[]   
}
export class Product{
    pk: number
    title: string
    slug: string
    image: string
    imageHover: string
    sticker: string
    stickerColor: string
    category?: {
        pk: number
        title: string
        slug: string
    }
    remains: {
        pk: number,
        title: string,
        price?: number,
        salePrice?: number,
        available: true
    }[] = []
    brand: {
        pk: number
        title: string
        slug: string
        miniDesc: string
    }
    minPrice: number = 0
    minSalePrice: number = 0 
    gallery: {
        pk: number
        image: string
    }[] = []
    discount: Product[] = []
    top: Product[] = []
    
    constructor(item: {
        pk: number
        title: string
        slug: string
        image: string
        imageHover: string
        sticker: string
        stickerColor: string
        remains: {
            pk: number,
            title: string,
            price?: number,
            salePrice?: number,
            available: true
        }[]
        brand: {
            pk: number
            title: string
            slug: string
            miniDesc: string
        }
        minPrice: number,
        minSalePrice: number
    } = {
        pk: 0,
        title: "",
        slug: "",
        image: "",
        imageHover: "",
        sticker: "",
        stickerColor: "",
        remains: [{
            pk: 0,
            title: "",
            price: 0,
            salePrice: 0,
            available: true
        }],
        brand: {
            pk: 0,
            title: "",
            slug: "",
            miniDesc: ""
        },
        minPrice: 0,
        minSalePrice: 0
    }){
        this.pk = item.pk
        this.title = item.title
        this.slug = item.slug
        this.image = item.image
        this.imageHover = item.imageHover
        this.sticker = item.sticker
        this.stickerColor = item.stickerColor
        this.remains = item.remains
        this.brand = item.brand

         
        this.minPrice = item.minPrice
        this.minSalePrice = item.minSalePrice
    }

    public static formatNumber(num: number){
        return `${num}`.replace(/(\d{1,3})(?=((\d{3})*)$)/g, " $1");
    }

    async LoadData(){
        await axios.get(`${host}product/${this.slug}/`,{ 
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            this.pk = res.data.product.pk
            this.title = res.data.product.title
            this.brand = res.data.product.brand
            this.category = res.data.product.category
            this.sticker = res.data.product.sticker

            this.minPrice = res.data.product.minPrice
            this.minSalePrice = res.data.product.minSalePrice
            this.gallery = res.data.product.gallery
            this.remains = res.data.product.remains

            this.discount = []
            res.data.discount.forEach((item: Product, index: number) => {
                this.discount.push(new Product({...item}))
            })

            this.top = []
            res.data.top.forEach((item: Product, index: number) => {
                this.top.push(new Product({...item}))
            })
            

        }).catch(error => {
            console.log(error)
        })

    }
}
export class Catalog{
    pageCount: number = 1
    page: number = 1
    products: Product[] = []
    category: CatalogCategory[] = []
    material: {
        pk: number
        title: string
    }[] = []
    sizes: {
        pk: number
        title: string
    }[] = []
    color: {
        pk: number
        title: string
        rgb: string
    }[] = []
    brands: {

    }[] = []
    gender = [
        {
            "title": "Женщинам",
            "pk": "female"
        },
        {
            "title": "Мужчинам",
            "pk": "male"
        },
        {
            "title": "Детям",
            "pk": "children"
        },
        {
            "title": "Унисекс",
            "pk": "unisex"
        },
    ]
    constructor(){}

    async LoadProduct(
        page: number, 
        minPrice: number, 
        maxPrice: number, 
        onlyAvailable: boolean, 
        onlySale: boolean, 
        sorting: string, 
        sizes: string, 
        colors: string,
        material: string,
        brands: string,
        search: string,
        category: string,
        compilations: string = "",
        gender: string = ""
    ){
        this.page = page
        await axios.get(`${host}product/`,{
            params: {
                page: page,
                minPrice: minPrice,
                maxPrice: maxPrice == 0 ? 99999999999999 : maxPrice,
                onlyAvailable: `${onlyAvailable}`,
                onlySale: `${onlySale}`,
                sorting: sorting,
                sizes: sizes,
                colors: colors,
                material: material,
                brands: brands,
                s: search,
                category: category,
                compilations: compilations,
                gender: gender
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            this.products = []
            res.data.data.forEach((item: Product, index: number) => {
                this.products.push(new Product({...item}))
            })
            this.pageCount = res.data.pageCount
        }).catch(error => {
            console.log(error)
        })
    }

    async LoadFavorite(page: number, favourites: number[]){
        this.page = page

        await axios.post(`${host}get-favourites/`,{
            products: favourites.toString()
        },
        {
            params: {
                page: page,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            this.products = []
            console.log(res.data)
            res.data.data.forEach((item: Product, index: number) => {
                this.products.push(new Product({...item}))
            })
            this.pageCount = res.data.pageCount
        }).catch(error => {
            console.log(error)
        })
    }

    async LoadCategory(type: string|null = null){
        await axios.get(`${host}filter/`,{
            params: {
                type: type
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => { 
            this.sizes = []     
            res.data.size.forEach((item: {title: string}, index: number) => {
                this.sizes.push({
                    pk: -1,
                    title: item.title
                }) 
            })       
            
            let targetID = 0
            if(type != null){
                res.data.category.forEach((item: any, index: number) => {
                    if(type == item.slug && item.parent == null){
                        targetID = item.pk
                        return false
                    }
                })
            }
            this.category = GetNode(res.data.category, type != null ? targetID : null)

            this.color = []
            res.data.color.forEach((item: any, index: number) => {
                this.color.push({
                    pk: item.color__pk,
                    title: item.color__title,
                    rgb: item.color__rgb
                })
            })

            this.material = []
            res.data.material.forEach((item: any, index: number) => {
                this.material.push({
                    pk: item.material__pk,
                    title: item.material__title, 
                })
            })

            this.brands = []
            res.data.brands.forEach((item: any, index: number) => {
                this.brands.push({
                    pk: item.brand__pk,
                    title: item.brand__title, 
                })
            })
        }).catch(function (error) { 
            console.log(error)
        }) 
        return true
    }
}

//-----------------------------------------------
export class CartProduct{
    pk: number
    count: number
    slug: string
    title: string
    image: string
    size: {
        title: string
        available: boolean
        price: number
        salePrice: number
        maxCount: number
    }
    
    constructor(item: {
        pk: number
        count: number
        product: {
            slug: string
            title: string
            image: string
        }
        size: {
            pk: number
            title: string
            available: boolean
            price: number
            salePrice: number
            maxCount: number
        },
    }){
        this.pk = item.pk
        this.count = Number(item.count)
        this.slug = item.product.slug
        this.title = item.product.title
        this.image = `https://api.teststeps.ru${item.product.image}`
        this.size = item.size
    }

    async DelProduct(userToken: string, cartToken: string){
        let data = {
            products: [],
            token: ""
        }
        await axios.delete(`${host}cart/${cartToken}/`, {
            params: {
                id: this.pk
            },
            headers: userToken == "" ? {
                'Content-Type': 'application/json',
            } : {
                'Content-Type': 'application/json',
                'Authorization': `Token ${userToken}`
            } 
        }).then(res => {
            data = {
                products: res.data.products,
                token: res.data.token
            }
        }).catch(error => {
            console.log(error)
        })

        return data
    }

    async UpdateCount(userToken: string, cartToken: string){
        let data = {
            products: [],
            token: ""
        }
        
        await axios.put(`${host}cart/${cartToken}/`, {
            count: this.count,
            id: this.pk
        }, {
            headers: userToken == "" ? {
                'Content-Type': 'application/json',
            } : {
                'Content-Type': 'application/json',
                'Authorization': `Token ${userToken}`
            } 
        }).then(res => {
            data = {
                products: res.data.products,
                token: res.data.token
            }
        }).catch(error => {
            console.log(error)
        })
        
        return data
    }
}
export class Cart{
    token: string = ""
    productCount = 0
    productFullPrice = 0
    productPrice = 0
    cartProducts: CartProduct[] = []
    orderAvailable: boolean = true

    constructor(){
    }

    //Возвращаем token корзины
    async GetToken(token: string){
        //Проверяем наличия токена
        if(localStorage.getItem("cart_stephsnks")){
            const userProps = JSON.parse(localStorage.getItem("cart_stephsnks") || "")
            this.token = userProps.token 
        }
        
        await axios.get(`${host}cart-token/`, {
            params: {
                token: this.token
            },
            headers: token == "" ? {
                'Content-Type': 'application/json',
            } : {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => {
            this.productCount = res.data.productCount
            localStorage.setItem("cart_stephsnks", JSON.stringify({
                "token": res.data.token
            }))
            this.token = res.data.token
        }).catch((error) => {
            console.log(error)
        })
    }

    async AddToCart(token: string, size: number, id: number){
        await axios.post(`${host}add-to-cart/`, {
            token: this.token,
            count: 1,
            size: size,
            id: id
        },{
            headers: token == "" ? {
                'Content-Type': 'application/json',
            } : {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            } 
        }).then((response) => {
            this.productCount = response.data.productCount
            localStorage.setItem("cart_stephsnks", JSON.stringify({
                "token": response.data.token
            }))
            this.token = response.data.token
        }).catch(function(error){
            console.log(error)
        })
    }

    async ClearCart(token: string){
        await axios.delete(`${host}cart/${this.token}/`, {
            params: {
                id: null
            },
            headers: token == "" ? {
                'Content-Type': 'application/json',
            } : {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            } 
        }).then(res => {
            this.cartProducts = []
            this.productCount = 0
            this.productPrice = 0
            this.productFullPrice = 0

            localStorage.setItem("cart_stephsnks", JSON.stringify({
                "token": res.data.token
            }))
            this.token = res.data.token
        }).catch(error => {
            console.log(error)
        })
    }

    async GetCart(token: string){
        await axios.get(`${host}cart/${this.token}/`, {
            params: {
                token: this.token
            },
            headers: token == "" ? {
                'Content-Type': 'application/json',
            } : {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            } 
        }).then((res) => {
            this.SetData(res.data) 
        }).catch((error) => {
            console.log(error)
        })
    }
 
    async McOrder(token: string, form: {
        fio: string
        phone: string
        email: string
        address: string
        city: string
        region: string
        index: string,
        deliveryType: string,
        contactType: string
    }){
        let data = {
            error: true,
            link: ""
        }
        await axios.post(`${host}mc-order/`, {
            token: this.token,
            ...form
        },{
            headers: token == "" ? {
                'Content-Type': 'application/json',
            } : {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            } 
        }).then((res) => {
            if(res.data.error == false){
                data.link = res.data.msg
                data.error = false
            }
        }).catch((error) => {
            console.log(error)
        })
        return data
    }

    SetData(data: {products: any[], token: string}){
        this.cartProducts = []
        this.productCount = 0
        this.productPrice = 0
        this.productFullPrice = 0
        this.orderAvailable = true

        data.products.forEach((item: any, index: number) => {
            this.cartProducts.push(new CartProduct({...item}))
            this.productCount = Number(item.count)
            this.productPrice = Number(item.size.salePrice) * Number(item.count)
            this.productFullPrice = Number(item.size.price) * Number(item.count)
            
            if(!item.size.available){
                this.orderAvailable = false
            }
        })

        localStorage.setItem("cart_stephsnks", JSON.stringify({
            "token": data.token
        }))
        this.token = data.token
    }

}
export class Order{
    pk: number 
    updateDate: string
    status: string
    product_order: [
        {
            pk: number
            count: number
            salePrice: number

            size?: {
                pk: number
                title: string
                available: boolean
                price: string
                salePrice: number
                maxCount: number
            }
            price?: number
            product?: {
                pk: number
                title: string
                slug: string
                image: string
            }
        }
    ]
    finPrice: number = 0
    finCount: number = 0

    constructor(item: {
        pk: number
        updateDate: string
        status: string
        product_order: [
            {
                pk: number
                count: number
                salePrice: number
            }
        ]
    } = {
        pk: 0,
        updateDate: "",
        status: "",
        product_order: [{
            pk: 0,
            count: 0,
            salePrice: 0
        }]
    }){
        this.pk = item.pk
        this.updateDate = item.updateDate
        this.status = item.status
        this.product_order = item.product_order

        this.product_order.forEach((item) => {
            this.finPrice += Number(item.salePrice) * Number(item.count)
            this.finCount += Number(item.count)
        })
    }

    async LoadData(token: string){ 
        await axios.get(`${host}account-orders/${this.pk}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        }).then((res) => { 
            this.finPrice = 0
            this.finCount = 0
            this.updateDate = res.data.updateDate
            this.product_order.splice(0, this.product_order.length)
            res.data.products.forEach((item: any) => {
                this.product_order.push(item)
                this.finPrice += Number(item.salePrice) * Number(item.count)
                this.finCount += Number(item.count)
            })

            switch(res.data.status){
                case "open":
                    this.status = 'корзина открыта'
                    break;
                case "w8":
                    this.status = 'ожидание оплаты'
                    break;
                case "pay":
                    this.status = 'оплачено'
                    break;
                case "complite":
                    this.status = 'выполнено'
                    break;
                case "cancelled":
                    this.status = 'отменено'
                    break;
            }
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
        })
        
    }
}
export class User{
    token: string = ""
    favourites: number[] = []
    cart: Cart = new Cart()
    pageCount: number = 1
    page: number = 1

    fio: string = ""
    phone: string = ""
    address: string = ""
    city: string = ""
    region: string = ""
    index: string = ""

    orders: Order[] = []

    constructor(){
        if(localStorage.getItem("userstephsnks")){
            const userProps = JSON.parse(localStorage.getItem("userstephsnks") || "")
            this.token = userProps.token 
        }

        if(localStorage.getItem("favouritestephsnks")){
            const userProps = JSON.parse(localStorage.getItem("favouritestephsnks") || "")
            this.favourites = userProps.favourites 
        }
    }

    async SetSetting(item: {
        fio: string
        phone: string
        address: string
        city: string
        region: string
        index: string
        password: string
        cPassword: string
    }){
        let data = {
            error: false,
            msg: ""
        }

        if(item.password != item.cPassword)
            return {
                error: true,
                msg: "Пароли должны совпадать"
            }

        if(item.password != "")
            if(!/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(item.password))
                return {
                    error: true,
                    msg: "Ваш пароль должен содержать не менее восьми (8) символов в том числе: по крайней мере один (1) буквы в верхнем регистре"
                }

        await axios.post(`${host}account-address/`, {...item},
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.token}`
            }
        }).then((res) => {
            console.log(res.data)
        }).catch(function(error){
            alert("error")
        })

        return data
    }

    async LoadSetting(){
        await axios.get(`${host}account-address/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.token}`
            }
        }).then((res) => { 
            this.fio = res.data.fio != null ? res.data.fio : ""
            this.phone = res.data.phone != null ? res.data.phone : ""
            this.address = res.data.address != null ? res.data.address : ""
            this.city = res.data.city != null ? res.data.city : ""
            this.region = res.data.region != null ? res.data.region : ""
            this.index = res.data.index != null ? res.data.index : ""
        }).catch(function(error){
            console.log(error)
        })
    }

    async LoadOrders(page: number){
        this.page = page
        
        await axios.get(`${host}account-orders/`, {
            params: {
                page: this.page
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.token}`
            }
        }).then((res) => { 
            this.orders = []
            res.data.data.forEach((item: Order, index: number) => {
                this.orders.push(new Order({...item}))
            })
            this.pageCount = res.data.pageCount
        }).catch(function(error){
            console.log(error)
        })
    }

    Logout(){
        localStorage.removeItem("userstephsnks")
        this.token = ""
    }

    async Registration(name: string, email: string, password: string, cPassword: string){
        let data = {
            error: false,
            msg: ""
        }
        
        if(password != cPassword)
            return {
                error: true,
                msg: "Пароли должны совпадать"
            }

        if(!/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(password))
            return {
                error: true,
                msg: "Ваш пароль должен содержать не менее восьми (8) символов в том числе: по крайней мере один (1) буквы в верхнем регистре"
            }

        await axios.post(`${host}registration/`, {
            name: name, 
            email: email, 
            password: password, 
            cPassword: cPassword
        },{
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            data = res.data
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
        })
        return data
    }

    async Login(email: string, password: string){
        let data = {
            error: false,
            msg: ""
        }

        await axios.post(`${host}login/`, { 
            email: email,
            password: password
        },{
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            data = res.data
            if(res.data.error == false){
                localStorage.setItem("userstephsnks", JSON.stringify({
                    "token": res.data.data.userToken
                }))
                localStorage.setItem("favouritestephsnks", JSON.stringify({
                    "favourites": res.data.data.favourites
                }))
                this.token = res.data.data.userToken
                this.favourites = res.data.data.favourites
            }
        }).catch(function(error){
            console.log(error)
        })
        return data
    }

    async SendPassword(email: string){
        let data = {
            error: false,
            msg: ""
        }

        await axios.post(`${host}password/`, { 
        },{
            params: {
                email: email
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => { 
            data = res.data
            console.log(res.data)
        }).catch(function(error){
            console.log(error)
        })
        return data
    }
 
    async SetFavourites(id: number){ 
        if(this.favourites.includes(id)){
            this.favourites = this.favourites.filter(function( obj ) {
                return obj != id
            })
        }else{
            this.favourites.push(id)
        }
        localStorage.setItem("favouritestephsnks", JSON.stringify({
            favourites: this.favourites
        }))

        if(this.token != ""){
            axios.post(`${host}favourites/`, {}, {
                params: {
                    'id': id
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+this.token
                }
            }).then((res) => {
                console.log(res.data)
            }).catch(function(error){
                console.log(error)
            })
        }
    }


}

//-----------------------------------------------

const GetNode = (array: [], parent: any) => {
    const nodeArray:CatalogCategory[] = []
    nodeArray.shift()
    array.forEach((item, index) => {
        if(item["parent"] == parent)
            nodeArray.push({
                "pk": item["pk"],
                "title": item["title"],
                "slug": item["slug"],
                "sort": item["sort"],
                "children": GetNode(array, item["pk"])
            })
    })
    return nodeArray
}