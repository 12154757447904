import { Link } from "react-router-dom"
import { host, Product } from "../classes"
import { useContext, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"

export const ProductInList = (props: any) => {
    const auth = useContext(GlobalContext)
    const [inFavourites, setInFavourites] = useState(auth.user.favourites.includes(props.data.pk))
    
    const UpdateFavourites = () => {
        auth.user.SetFavourites(props.data.pk)
        setInFavourites(!inFavourites)
    }

    return(
        <div className="product-item__wrapper">
            <div 
                className={`product-item__like ${inFavourites ? "active":""}`}
                onClick={()=>{UpdateFavourites()}}
            >
                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.709 2.06089C13.3792 1.72455 12.9875 1.45775 12.5564 1.27572C12.1254 1.09369 11.6633 1 11.1967 1C10.7301 1 10.2681 1.09369 9.837 1.27572C9.40593 1.45775 9.01428 1.72455 8.68441 2.06089L7.99983 2.75857L7.31524 2.06089C6.64894 1.38184 5.74523 1.00035 4.80294 1.00035C3.86064 1.00035 2.95694 1.38184 2.29063 2.06089C1.62433 2.73994 1.25 3.66092 1.25 4.62124C1.25 5.58157 1.62433 6.50255 2.29063 7.1816L2.97522 7.87928L7.99983 13L13.0244 7.87928L13.709 7.1816C14.039 6.84543 14.3008 6.44628 14.4795 6.00697C14.6581 5.56765 14.75 5.09678 14.75 4.62124C14.75 4.14571 14.6581 3.67484 14.4795 3.23552C14.3008 2.79621 14.039 2.39706 13.709 2.06089V2.06089Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
            <Link className="product-item" to={`/product/${props.data.slug}`}>
                <div className="product-item__img-two" style={{backgroundImage: `url('${host}${props.data.imageHover}')`}}>
                    <div className="product-item__img" style={{backgroundImage: `url('${host}${props.data.image}')`}}>
                        {
                            props.data.sticker ? (
                                <div className="product-item__slider" style={{background: `${props.data.stickerColor}`}}>{props.data.sticker}</div>
                            ):""
                        }
                        <div className="product-item__sizes">
                            {  
                                props.data.remains.length ? (
                                    props.data.remains.map((item: any, index: number) => {
                                        return(
                                            <span className={`${item.available == false ? "none":""}`}>{item.title}</span>
                                        )
                                    })
                                ):""
                                
                            } 
                        </div>
                    </div>
                </div>
                <p className="product-item__title">
                    {props.data.title}
                    {
                        props.data.brand ? ( 
                            <span>{props.data.brand.title}</span>
                        ):""
                    }
                </p>
                <p className="product-item__price">
                    {
                        props.data.minPrice == props.data.minSalePrice ? (
                            `${Product.formatNumber(props.data.minPrice)} ₽`
                        ):(
                            <>
                                <span className="old">От {Product.formatNumber(props.data.minPrice)} ₽</span>
                                <span className="new">  От {Product.formatNumber(props.data.minSalePrice)} ₽</span>
                            </>
                        )
                    }
                </p>
            </Link>
        </div>
    )
} 